












import {
  Component,
} from 'vue-property-decorator';
import FormBase from '../FormBase';
import MapChartData from './modelsMap';

@Component
export default class FormBar extends FormBase {
  data: MapChartData = {
    mapType: 'points',
    title: '',
    description: '',
    icon: '',
    latField: '',
    lonField: '',
    intensityField: '',
  };
}
